import Vue from "vue";
import VueRouter from "vue-router";
import Work from "../views/Work.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "work",
    component: Work,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/work/:id",
    name: "work-id",
    component: () =>
      import(/* webpackChunkName: "work" */ "../views/Work_ID.vue"),
  },
  {
    path: "/sports",
    name: "sports",
    component: () =>
      import(/* webpackChunkName: "work" */ "../views/Sports.vue"),
  },
];

// Only add the Admin route if in dev-mode
if (process.env.NODE_ENV === "development") {
  routes.push({
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
  });
}

// Error 404 Page
routes.push({
  path: "*",
  component: () => import(/* webpackChunkName: "error" */ "../views/Error.vue"),
});

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return !savedPosition ? { x: 0, y: 0 } : savedPosition;
  },
});

export default router;
